
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  filters: {
    runState(value: string) {
      const map = new Map([
        ['0', '割草'],
        ['1', '待机'],
        ['2', '回仓'],
        ['3', '休眠等待'],
        ['4', '充电'],
        ['5', '故障']
      ])
      return map.get(value) ? map.get(value) : ''
    }
  }
})
export default class History extends Vue {
  @Prop() readonly deviceId!: string;
  customer = ''
  deviceTypeId = ''
  dataTypeList = [{ label: '客流', val: '2' }, { label: '蹲位', val: '3' }, { label: '空气', val: '4' }, { label: '用水用电', val: '8' }, { label: '耗材', val: '12' }]
  dataType = ''
  page = 1
  size = 10
  total = 0
  loading = false
  tableData = []
  created() {
    this.deviceTypeId = this.$route.query.typeid as string
    this.customer = this.$route.query.customer as string
    if (!this.customer || this.customer === 'null') {
      this.$message.warning('该设备未分配,暂无权限查看')
      return false
    }
    if (this.deviceTypeId === '1031') {
      this.dataType = '2'
    }
    this.deviceTypeId !== '1002' && this.loadData()
  }

  loadData() {
    this.loading = true
    this.$axios.get(this.$apis.devices.getHistoryByTypeId(this.deviceTypeId), {
      customer: this.customer,
      deviceId: this.deviceId,
      dataType: this.dataType,
      page: this.page,
      size: this.size
    }).then(res => {
      this.tableData = res.list
      this.total = res.total
    }).finally(() => {
      this.loading = false
    })
  }

  getDeviceStatusName(status: string) {
    switch (status) {
      case '0': return '正常'
      case '1': return 'sos'
      case '2': return '低电量'
      default: return ''
    }
  }

  dataTypeChange() {
    this.page = 1
    this.loadData()
  }
}
